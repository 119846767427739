/* line 65, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card {
	background-color: rgb(var(--color-global-primary));
	line-height: 1.5em;
	margin-bottom: 1px;
	position: relative;
}

/* line 71, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card--spaced {
	padding: 10px 20px;
}

/* line 75, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card--centered {
	text-align: center;
}

/* line 79, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card__broker-logo {
	max-height: 110px;
	box-sizing: border-box;
	width: 100%;
	height: 110px;
	display: flex;
}

/* line 86, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card__broker-logo .advertiser-logo__wrap {
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	flex: 1 1 100%;
	background: rgb(var(--color-global-positive));
	margin: 7px;
	padding: 5px;
	max-width: unset;
}

/* line 96, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card__broker-logo .advertiser-logo__wrap a {
	flex: 1 1 100%;
}

/* line 100, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card__broker-logo .advertiser-logo__wrap img {
	max-height: 100%;
}

/* line 106, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card--simplified {
	line-height: 1;
	background-color: rgb(var(--color-dynamic-background-accent));
}

/* line 113, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card--simplified .c-radio {
	padding: 10px 0 10px 10px;
	flex: 1;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-contact-card--simplified .c-radio {
	padding: 10px 10px 10px 0;
}

/* line 120, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card--simplified .contact-card__details {
	flex: 10;
	padding: 10px 10px 10px 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-contact-card--simplified .contact-card__details {
	padding: 10px 0 10px 10px;
}

/* line 125, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card--simplified .contact-card__photo {
	max-width: 55px;
}

/* line 133, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card--simplified .contact-card__phones {
	margin-bottom: unset;
}

/* line 146, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card .c-radio {
	vertical-align: top;
}

/* line 150, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card .agent__photo {
	margin-bottom: 10px;
}

/* line 157, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__details {
	padding: 0 10px;
	display: inline-block;
	min-width: 0;
	font-size: inherit;
}

/* line 178, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__office {
	color: rgb(var(--color-global-primary));
}

/* line 182, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__office, .contact-card__phones, .contact-card__title {
	margin-bottom: 10px;
}

/* line 188, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__address {
	margin-bottom: 10px;
}

/* line 191, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__address--spacing {
	margin-bottom: 20px;
	margin-top: 10px;
}

/* line 197, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__languages {
	font-size: 0.92em;
	margin-bottom: 10px;
}

/* line 202, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__photo {
	display: inline-block;
	border: 1px solid inherit;
	max-height: 80px;
	width: 120px;
	overflow: hidden;
	flex: 0 0 auto;
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 202, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card__photo {
		max-height: 120px;
		width: 90px;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 202, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card__photo {
		max-height: 80px;
		width: 90px;
	}
}

/* line 220, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.c-contact-card--pull-image .contact-card__photo {
	margin-left: -20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-contact-card--pull-image .contact-card__photo {
	margin-left: initial;
	margin-right: -20px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-contact-card--pull-image .contact-card__photo {
		margin-left: 0;
	}
}

/* line 224, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__photo--left {
	margin-right: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .contact-card__photo--left {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .contact-card__photo--left {
		margin-right: 0;
	}
}

/* line 228, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__photo--big {
	height: 300px;
	width: 200px;
}

/* line 233, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__photo--inline {
	vertical-align: top;
	margin-top: 5px;
}

/* line 239, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__clear::after {
	content: "";
	display: block;
	height: 0;
	line-height: 0;
	clear: both;
}

/* line 247, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__advertiser-logo {
	display: inline-block;
	margin-top: 10px;
	height: 40px;
}

/* line 254, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__name {
	color: initial;
}

/* line 264, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.advertiser-logo__wrap {
	max-width: 200px;
}

/* line 268, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-info__agent-name,
.phonecontainerclass {
	display: block;
	margin-bottom: 2px;
}

/* line 274, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-info__agent-name {
	font-weight: 600;
	font-size: 1.083em;
}

@media only screen and (max-width: 47.999em) {
	/* line 274, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-info__agent-name {
		font-size: 1.2617em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 274, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-info__agent-name {
		font-size: 1.083em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 274, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-info__agent-name {
		font-size: 1.083em;
	}
}

/* line 284, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__phones .o-phone {
	font-size: 1em;
}

@media only screen and (max-width: 47.999em) {
	/* line 284, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card__phones .o-phone {
		font-size: 1.165em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 284, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card__phones .o-phone {
		font-size: 1em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 284, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card__phones .o-phone {
		font-size: 1em;
	}
}

/* line 290, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__details .contact-info__agent-email {
	font-size: 1em;
}

@media only screen and (max-width: 47.999em) {
	/* line 290, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card__details .contact-info__agent-email {
		font-size: 1.165em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 290, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card__details .contact-info__agent-email {
		font-size: 1em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 290, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card__details .contact-info__agent-email {
		font-size: 1em;
	}
}

/* line 297, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__agent-social-links .icon {
	padding: 4px;
}

@media only screen and (max-width: 29.999em) {
	/* line 297, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card__agent-social-links .icon {
		margin-right: 5px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .contact-card__agent-social-links .icon {
		margin-right: initial;
		margin-left: 5px;
	}
}

@media only screen and (max-width: 29.999em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .contact-card__agent-social-links .icon {
		margin-right: 0;
	}
}

/* line 306, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__multioffices {
	margin-bottom: 10px;
}

/* line 309, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.m-agentcontactinfo .contact-card__multioffices {
	margin-top: 20px;
}

/* line 314, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__office-items {
	margin: 10px 0;
	padding-top: 20px;
	padding-left: 10px;
}

/* line 319, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__multioffices .contact-card__office-items {
	padding-right: 20px;
	padding-left: 5px;
	padding-top: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .contact-card__multioffices .contact-card__office-items {
	padding-right: initial;
	padding-left: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .contact-card__multioffices .contact-card__office-items {
	padding-left: initial;
	padding-right: 5px;
}

/* line 325, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__office-items .contact-card__address {
	font-size: inherit;
}

/* line 331, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__more-offices,
.contact-card__less-offices {
	padding-right: 15px;
	display: inline;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .contact-card__more-offices, .is-rtl
.contact-card__less-offices {
	padding-right: initial;
	padding-left: 15px;
}

/* line 338, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__multioffices .contact-card__multioffices-wrapper {
	padding: 20px 5px 10px 5px;
}

/* line 343, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__multioffices-number {
	color: rgb(var(--color-global-secondary));
}

/* line 348, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.is-expanded .contact-card__more-offices {
	display: none;
}

/* line 353, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card__less-offices {
	display: none;
}

/* line 356, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.is-expanded .contact-card__less-offices {
	display: inline-block;
}

/* line 364, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card--withactivelistings .contact-card__details {
	padding-left: 20px;
	display: flex;
	flex-direction: column;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .contact-card--withactivelistings .contact-card__details {
	padding-left: initial;
	padding-right: 20px;
}

/* line 370, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card--withactivelistings .contact-card__phones {
	margin-bottom: 0;
}

/* line 374, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card--withactivelistings .contact-card__photo {
	max-height: 80px;
	width: 60px;
}

/* line 380, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card--withactivelistings .contact-card__activelistingscount {
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	margin-top: auto;
}

@media only screen and (max-width: 47.999em) {
	/* line 380, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card--withactivelistings .contact-card__activelistingscount {
		font-size: 0.9em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 380, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card--withactivelistings .contact-card__activelistingscount {
		font-size: 0.9em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 380, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
	.contact-card--withactivelistings .contact-card__activelistingscount {
		font-size: 0.9em;
		line-height: 1.4;
	}
}

/* line 391, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card--withactivelistings .contact-info__agent-name {
	margin-top: 5px;
	margin-bottom: 2.5px;
}

/* line 396, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card--withactivelistings .c-radio {
	margin-right: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .contact-card--withactivelistings .c-radio {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .contact-card--withactivelistings .c-radio {
		margin-right: 0;
	}
}

/* line 400, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.contact-card--withactivelistings .o-phone {
	margin-bottom: 2.5px;
}

/* line 407, scss/80-themes/AffinityDefault/60-components/contact-card.scss */
.sticky-contact-section .c-contact-card {
	margin-bottom: 0;
	border-bottom: 5px solid rgb(var(--color-global-positive));
}

/*# sourceMappingURL=../../../../true */